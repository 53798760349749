import React from "react";
import service_card1 from "../../assets/Techmintlab_box.png";
import { Card, ServicesCard } from "../../components/Tabs/Tabs";
import hero_image from "../../assets/hero_image.jpg";
import service_card2 from "../../assets/servicegif.gif";
import service_card3 from "../../assets/googleads.png";
import service_card4 from "../../assets/supercharge.png";
import service_card5 from "../../assets/design.png";
import service_card6 from "../../assets/techmintlab_digitalmarketing.png";
import Testimonial from "./Testimonial";
import FAQ from "./FAQ";
import Corousel from "./Corousel";
import { Link } from "react-router-dom";
import CodingTechnology from "../../components/Tabs/CodingTechnology";
import FounderDetails from "../../components/Tabs/FounderDetails";
import InternshipForm from "../../components/JobForm";

function Home() {
  const cardContent = [
    {
      imageSrc: service_card1,
      header: "Web Design Fundamentals",
      text: "The most important thing for designing a website is to prepare the structure of the website, for this we have a team of graphic designers to prepare the track structure of the website and developers and engineers for web development",
      buttonText: "Contact Us",
    },
    {
      imageSrc: service_card2,
      header: "Front-End Web Development",
      text: "Nowadays, the UI of  website is the most important thing, for that we have front end developers who will work hard day and night to help you in getting a beautiful and shining website ready for you.",
      buttonText: "Contact Us",
    },
    {
      imageSrc: service_card3,
      header: "Google Ads",
      text: "Now you have created a website but to reach your vision and reach it for decades, you will have to take the help of Google Ads, for that the digital marketing team will help.",
      buttonText: "Contact Us",
    },
    {
      imageSrc: service_card4,
      header: "Backend Engineer",
      text: "Now your data has started coming to you, the audience has started explaining to you, when they try to reach you, they will store some data or the other, for that the backend engineer is ready to help.",
      buttonText: "Contact Us",
    },
    {
      imageSrc: service_card5,
      header: "Graphic Designing",
      text: "Now comes the turn to introduce the graphic designer team, our team of graphic tv9er is very good, they understand the big application and small things quickly and try to reflect it in the picture.",
      buttonText: "Contact Us",
    },
    {
      imageSrc: service_card6,
      header: "Degital Marketing",
      text: "Now in digital marketing, the digital marketing team is always ready to do the work of getting the website ranked, so it is not possible that there is no need for them.",
      buttonText: "Contact Us",
    },
  ];
  return (
    <>
      <section className="py-12 bg-yellow-400 flex  flex-col md:flex-col items-center justify-center">
        <div className="w-[70%] md:w-1/2 flex items-center justify-center py-3 px-4 bg-gradient-to-r from-yellow-300 via-orange-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-yellow-500/80 font-medium rounded-lg border border-zinc-100">
          <div className="text-center">
            <span className="text-black sm:text-2xl text-md font-semibold font-mono">
              Unlock{"  "}
            </span>
            <span className="text-white sm:text-2xl text-md font-semibold font-'Be Vietnam Pro'">
              Your Creative Potential
            </span>
          </div>
        </div>

        <div className="w-full md:w-1/2 text-center p-4 mt-4 md:mt-0">
          <p className="text-neutral-800  font-extralight text-2xl text-md  font-'Be Vietnam Pro' leading-normal">
            Who are we?
          </p>{" "}
          <h4 className="my-3  sm:text-4xl text-xl font-extrabold text-red-600">
            We are a team of developers who are known for creating very modern
            and super fast software.
          </h4>
          <div className="my-4">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              <Link to="/services">Explore Services</Link>
            </button>
          </div>
        </div>
      </section>

      <CodingTechnology />

      <section>
        {/* <div className="w-full h-full sm:p-[100px] p-10 bg-opacity-20 rounded-md border-neutral-100 justify-center items-center  inline-flex">
        </div> */}
        <Corousel />
       
      </section>
      {/* Services */}
      <section className="px-20  bg-gray-800 pt-5">
        <div className=" flex justify-center ">
          <h6 className="text-pink-500 sm:text-3xl text-xl font-semibold font-['Be Vietnam Pro'] leading-10 ">
            Our Services
          </h6>
        </div>
        <div className=" flex justify-start ">
          <p className=" text-gray-300 sm:text-2xl py-5 text-3xl font-normal font-['Be Vietnam Pro'] leading-normal">
            We do Mobile Application Web Development and Graphic Designing as
            well as Digital Marketing and Google Ads.
          </p>
        </div>
      </section>

      {/* services cards */}
      <section className="bg-gray-800 py-2 grid md:grid-cols-3 grid-rows-1 px-1 sm:px-20 gap-x-2  space-y-5">
        {cardContent.map((content, index) => (
          <div key={index} className="">
            <ServicesCard
              cardHeader={content.header}
              text={content.text}
              imageSrc={content.imageSrc}
              buttonText={content.buttonText}
            />
          </div>
        ))}
      </section>

      {/* Benefits */}
      <section className="  bg-fuchsia-500 px-10 py-10">
        <div className="flex justify-start mb-8">
          <h6 className="uppercase text-red-800 text-3xl font-semibold font-'Be Vietnam Pro' leading-10">
            Benefits
          </h6>
        </div>
        <div className="flex justify-start mb-10">
          <p className="text-white font-extrabold text-xl  font-'Be Vietnam Pro' leading-normal">
            If Contracting , we will get you proper certificate and written
            action and proceed with further work so that the relationship
            between us and you can become very strong.
          </p>
        </div>

        {/* benefits Cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="bg-gradient-to-r from-blue-200 to-blue-300 shadow-md p-6 rounded-md">
            <Card
              cardHeader="Web Application Development"
              text=" Modern, responsive, and user-friendly web applications."
            />
          </div>
          <div className="bg-gradient-to-r from-green-200 to-green-300 shadow-md p-6 rounded-md">
            <Card
              cardHeader="Mobile App Development"
              text=" iOS, Android, and cross-platform apps for a seamless mobile experience."
            />
          </div>
          <div className="bg-gradient-to-r from-blue-200 to-blue-300 shadow-md p-6 rounded-md">
            <Card
              cardHeader="Custom Software Development"
              text="Tailored solutions to meet your unique business needs."
            />
          </div>
          <div className="bg-gradient-to-r from-blue-200 to-blue-300 shadow-md p-6 rounded-md">
            <Card
              cardHeader="Turning Ideas into Reality"
              text="Our development process is transparent, collaborative, and results-driven."
            />
          </div>
        </div>
      </section>

      {/* Testimonials */}

      <section className="px-20">
        <div className=" flex justify-start ">
          <h6 className="text-red-800  sm:text-3xl text-center text-3xl my-5 font-semibold font-['Be Vietnam Pro'] leading-10 ">
            Technologies We Work With Staying Ahead of the Curve
          </h6>
        </div>
        <div className=" flex justify-start ">
          <p className=" text-zinc-600 text-md my-4 sm:text-xl font-normal font-['Be Vietnam Pro'] leading-normal">
            Our software development services are designed to transform your
            ideas into powerful, scalable solutions. Whether you're a startup or
            an enterprise, we have the expertise to bring your vision to life.
          </p>
        </div>
      </section>
      <Testimonial />

      <FAQ />
      <FounderDetails />
    </>
  );
}

export default Home;
