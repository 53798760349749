import React from "react";
import ContactForm from "./ContactForm";
import ContactDetails from "./ContactDetails";
import contact from "../../assets/contact.jpg";

function Contact() {
  return (
    <>
      <section className="flex sm:flex-col flex-row justify-between px-6 sm:px-24 py-12">
        <div className="border-b border-zinc-200 justify-start items-center inline-flex flex-col sm:flex-row">
          <h3 className="grow shrink basis-0 text-neutral-800 sm:text-3xl text-2xl font-semibold font-['Be Vietnam Pro'] leading-10 mb-4 sm:mb-0">
            Contact Us
          </h3>
          <div className="grow shrink basis-0 text-xl text-zinc-600 sm:text-lg text:sm font-normal font-['Be Vietnam Pro'] leading-relaxed">
            Your success is our success. We believe in building lasting
            partnerships with our clients. Understanding your unique goals and
            challenges allows us to tailor solutions that not only meet but
            exceed your expectations. Our client-centric approach ensures that
            your journey with TechMintLab is not just a service engagement but a
            collaborative experience.
          </div>
        </div>
      </section>

      {/* contact form */}
      <div
        className="flex justify-center bg-cover bg-center bg-fixed py-10"
        style={{ backgroundImage: `url(${contact})` }}
      >
        <ContactForm />
      </div>

      <div className="">
        <ContactDetails />
      </div>
    </>
  );
}

export default Contact;
