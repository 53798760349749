import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://techmintlabbackend.onrender.com/submitData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        toast.success("We Will Contact You Shortly!", {
          position: "top-right",
        });
        setFormData({
          name: "",
          email: "",
          message: "",
          phone: "",
        });
      } else {
        toast.error("failed", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
    }
  };

  return (
    <form
      className="w-[80%] sm:w-[60%] mx-auto mt-8 bg-gradient-to-r from-orange-900 to-blue-800 bg-opacity-75 p-8 border border-yellow-500 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <ToastContainer />
      <h2 className="sm:text-2xl text-xl text-white font-semibold mb-4">
        We will Contact You Quickly! Just Fill this form!
      </h2>
      <div className="mb-4">
        <label htmlFor="name" className="block text-white font-medium mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md"
          placeholder="Your Name"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-white font-medium mb-2">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md"
          placeholder="Your Email"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-white font-medium mb-2">
          Phone
        </label>
        <input
          type="phone"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          className="w-full p-2 border rounded-md"
          placeholder="Your Phone"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-white font-medium mb-2">
          Message
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          rows="4"
          className="w-full p-2 border rounded-md"
          placeholder="Your Message"
          required
        ></textarea>
      </div>
      <div className="text-end">
        <button
          type="submit"
          className="bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 text-white font-medium rounded-md py-2 px-4"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
