import React from "react";

function Careers() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <header className="bg-blue-600 w-full py-6">
        <h1 className="text-white text-3xl text-center">TechMintLab Careers</h1>
      </header>
      <main className="flex-grow w-full flex flex-col items-center mt-8 px-4">
        <h2 className="text-2xl font-semibold mb-4">We're Hiring!</h2>
        <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl w-full">
          <h3 className="text-xl font-semibold mb-2">WordPress Developer</h3>
          <p className="mb-4">
            TechMintLab is looking for a skilled WordPress Developer to join our
            team. If you have a passion for web development and experience with
            WordPress, we'd love to hear from you!
          </p>
          <h4 className="text-lg font-semibold mb-2">Responsibilities:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Developing and maintaining WordPress websites.</li>
            <li>Collaborating with design and marketing teams.</li>
            <li>Ensuring website performance and security.</li>
            <li>Customizing themes and plugins.</li>
          </ul>
          <h4 className="text-lg font-semibold mb-2">Requirements:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Proven experience with WordPress development.</li>
            <li>Strong understanding of PHP, HTML, CSS, and JavaScript.</li>
            <li>Experience with responsive and adaptive design.</li>
            <li>Good problem-solving skills and attention to detail.</li>
          </ul>
          <h4 className="text-lg font-semibold mb-2">How to Apply:</h4>
          <p className="mb-4">
            Send your resume and portfolio to{" "}
            <a href="mailto:careers@techmintlab.com" className="text-blue-600">
              careers@techmintlab.com
            </a>
            . We look forward to hearing from you!
          </p>
        </div>
      </main>
    
    </div>
  );
}

export default Careers;
