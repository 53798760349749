import React, { useState, useEffect } from "react";
import hero_image from "../../assets/softwarecarousel.png";
import service_card2 from '../../assets/digitalamrketingmain.png'

const Testimonial = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    hero_image,
    
    service_card2 ,
    hero_image,
    service_card2 
  ];

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [activeSlide]); 
  return (
    <div>
      <div className="relative text-center  sm:px-44 px-10 w-full" data-carousel="slide">
        {/* Carousel wrapper */}
        <div className="relative h-56 overflow-hidden rounded-lg md:h-[35rem]">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`${
                index === activeSlide ? "block" : "hidden"
              } duration-700 ease-in-out`}
              data-carousel-item
            >
              <img
                src={slide}
                className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </div>
        {/* Slider controls */}
        <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
          <button
            type="button"
            className="w-3 h-3 rounded-full"
            aria-current="true"
            aria-label={`Slide ${activeSlide + 1}`}
            data-carousel-slide-to={activeSlide}
          ></button>
        </div>
        {/* Slider controls */}
        <button
          type="button"
          className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={handlePrev}
        >
          {/* ... (unchanged) ... */}
        </button>
        <button
          type="button"
          className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={handleNext}
        >
          {/* ... (unchanged) ... */}
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
