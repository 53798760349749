import React from "react";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdAddIcCall } from "react-icons/md";

function FooterContact() {
  return (
    <div>
      <section className="px-8 py-8 sm:px-20 sm:py-20 flex justify-center items-center text-center flex-col">
        <div className="text-center">
          <h6 className="text-white sm:text-3xl text-xl font-semibold font-'Be Vietnam Pro' leading-10">
            Ready to Grow your Business
          </h6>
        </div>
        <div className="mt-4 text-center">
          <p className="text-white sm:text-xl text-md font-normal font-'Be Vietnam Pro' leading-normal">
            Still think it's not enough with the above details? Our team is here
            to help you.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center mt-6 sm:space-x-4">
          <div className="mb-4 sm:mb-0">
            <button className="w-full sm:w-auto px-6 py-3.5 text-base text-white inline-flex items-center bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg font-medium rounded-lg">
              <HiOutlineMailOpen className="w-6 h-6 mr-2" />
              <a href="mailto:techmintlab@gmail.com"> Drop a mail</a>
            </button>
          </div>
          <div>
            <button className="w-full sm:w-auto px-6 py-3.5 text-base text-white inline-flex items-center bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg">
              <MdAddIcCall className="w-6 h-6 mr-2" />
              <a href="tel:+919663375712">Call us</a>
            </button>
          </div>
        </div>
      </section>

      <hr class="mb-10 border-green-700 border-dotted sm:mx-auto dark:border-gray-700 lg:my-8" />
    </div>
  );
}

export default FooterContact;
