// Header.js
import logo from "../assets/logo.png";
import keybordgif from "../assets/keyboard.gif";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaSearch,
  FaUser,
  FaShoppingCart,
  FaBars,
  FaCode,
  FaTimes,
  FaLaptopCode,
} from "react-icons/fa";
import { GoCodescan } from "react-icons/go";
import { MdContacts } from "react-icons/md";
const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <header className="bg-gradient-to-r px-3 sticky  w-full top-0 z-50 from-green-900 to-blue-500 text-white py-2">
      <div className="container  mx-auto flex flex-wrap justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            {" "}
            <img
              src={logo}
              alt="Logo"
              className="w-[70px] rounded-full cursor-pointer h-[70px] mr-2"
            />
          </Link>{" "}
        </div>
        <div className="flex   items-center mt-4 lg:mt-0">
          <div className="relative mr-4 flex items-center gap-x-2 text-[13px] sm:text-2xl">
            <span className="hidden font-extralight sm:block">
              We Are Creating ExtraOrdinary Software
            </span>{" "}
            <img
              src={keybordgif}
              className=" rounded-full  shadow-sky-100 shadow-xl h-[60px] w-[60px]"
              alt=""
            />
          </div>
          <Link to="/services">
            <button className="flex items-center  text-white hover:text-gray-300">
              <FaCode className="mr-1" />
              <span className="hidden sm:block"> Services</span>
            </button>
          </Link>
          <Link to="/about">
            <a
              href="#"
              className="hover:text-gray-300 ml-4 flex items-center gap-x-1"
            >
              <GoCodescan className="mr-1" />
              <span className="hidden sm:block"> About Us</span>
            </a>
          </Link>
          {/* <Link to="/contact">
            <a
              href="#"
              className="hover:text-gray-300 ml-4 flex items-center gap-x-1"
            >
              <MdContacts className="mr-1" />
              <span className="hidden sm:block">Contact</span>
            </a>
          </Link> */}
          <Link to="/contact">
            <a
              href="#"
              className="hover:text-gray-300 ml-4 flex items-center gap-x-1"
            >
              <FaLaptopCode className="mr-1" />
              <span className="hidden sm:block">Contact</span>
            </a>
          </Link>

          {/* Responsive Navigation Toggle Button */}
          <button
            className="lg:hidden ml-5 focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Responsive Navigation Menu */}
        {isMenuOpen && (
          <div className="lg:hidden w-full mt-4">
            <a
              href="tel:+919663375712"
              className="block text-white py-2 border-b border-gray-400 hover:text-gray-300"
            >
              Call:+919663375712
            </a>
            <a
              href="mailto:info@techmintlab.com"
              className="block text-white py-2 border-b border-gray-400 hover:text-gray-300"
            >
              info@techmintlab.com
            </a>
            <a
              href="#"
              className="block text-white py-2 border-b border-gray-400 hover:text-gray-300"
            ></a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
