import React from "react";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineEnvironment,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";

function ContactDetails() {
  return (
    <section className="flex flex-row bg-blue-50 justify-center overflow-x-auto">
      <div className="p-20  flex-row justify-start items-start gap-8 inline-flex">
        <div className="self-stretch grow shrink shadow basis-0 p-2 bg-yellow-100 rounded-lg border border-zinc-100 flex-col justify-center items-center gap-5 flex">
          <div className="p-3.5 bg-orange-600 rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 flex">
            <AiOutlineMail className="w-6 h-6 relative text-white" />
          </div>
          <div className="self-stretch text-center text-neutral-900 sm:text-md text-sm font-normal font-['Be Vietnam Pro'] leading-relaxed ">
            techmintlab@gmail.com
          </div>
        </div>
        <div className="w-[200px] self-stretch grow shadow shrink basis-0 p-5 bg-yellow-100 rounded-lg border border-zinc-100 flex-col justify-center items-center gap-5 flex">
          <div className=" p-3.5 bg-orange-600  rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 flex">
            <AiOutlinePhone className="w-6 h-6 relative text-white" />
          </div>
          <div className="self-stretch text-center text-neutral-900 sm:text-md text-sm font-normal font-['Be Vietnam Pro'] leading-relaxed ">
            +91 90533 79674
          </div>
        </div>
        <div className="self-stretch grow shrink shadow basis-0 p-5 bg-yellow-100 rounded-lg border border-zinc-100 flex-col justify-center items-center gap-5 flex">
          <div className="p-3.5 bg-orange-600 rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 flex">
            <AiOutlineEnvironment className="w-6 h-6 relative text-white" />
          </div>
          <div className="self-stretch text-center text-neutral-900 sm:text-md text-sm font-normal font-['Be Vietnam Pro'] leading-relaxed">
            Noida , Uttar Pradesh , India
          </div>
        </div>
        <div className="self-stretch grow shrink shadow basis-0 p-5 bg-yellow-100 rounded-lg border border-zinc-100 flex-col justify-center items-center gap-5 flex">
          <div className="justify-start items-start gap-3.5 inline-flex">
            <div className="p-3.5 bg-green-900 text-slate-300 rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 inline-flex">
              <a href="https://www.facebook.com/people/Techmint-Lab/61554739299740/">
                {" "}
                <AiOutlineFacebook className="w-6 h-6 relative text-white" />
              </a>
            </div>
            <div className="p-3.5 bg-green-900 text-slate-300 rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 inline-flex">
              <a href="https://www.instagram.com/techmintlab_">
                <AiOutlineInstagram className="w-6 h-6 relative" />
              </a>
            </div>
            <div className="p-3.5 bg-green-900 text-slate-300 rounded-lg border border-zinc-100 flex-col justify-start items-start gap-2.5 inline-flex">
              <a href="https://www.linkedin.com/company/techmintlab">
                {" "}
                <AiOutlineLinkedin className="w-6 h-6 relative" />
              </a>
            </div>
          </div>
          <div className="self-stretch text-center text-neutral-900 text-md font-normal font-['Be Vietnam Pro'] leading-relaxed">
            Follow Us
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactDetails;
