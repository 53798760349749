import React, { useEffect } from "react";
import { FaTrophy, FaLightbulb, FaUserTie, FaBook } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "./aboutus.css";

function About() {
  useEffect(() => {
    AOS.init({ duration: 3000, twice: true });
  }, []);

  const about = "url(`about`)";
  const sectionStyle = {
    backgroundImage: about,
    backgroundSize: "cover",
  };
  return (
    <>
      <section className="flex sm:flex-col flex-row justify-between px-4 sm:px-28 py-10 bg-gray-100">
        <div className="border-b border-gray-300 ">
          <h3 className="text-blue-700 text-center text-4xl font-semibold font-be-vietnam-pro leading-10 mb-4 sm:mb-0">
            About Us
          </h3>
          <div className="text-gray-600 text-lg font-normal font-be-vietnam-pro leading-relaxed ml-2">
            Welcome to TechMintLab, your partner in digital innovation. We're on
            a mission to empower individuals and businesses in the ever-evolving
            digital landscape. Our commitment to quality, creativity, and
            continuous learning sets us apart.
          </div>
        </div>
      </section>

      <div className="flex flex-col mt-2 sm:mt-0 px-6 sm:px-32 py-12">
        <h2 className="text-red-900 text-3xl mb-4 font-semibold">
          Our Journey
        </h2>
        <p className="text-gray-700 mb-6">
          Founded in 2023, TechMintLab has grown from a small team of
          enthusiasts to a leading force in the tech industry. Our journey is
          marked by a passion for technology, a dedication to our clients, and a
          relentless pursuit of excellence.
        </p>
        <p className="text-gray-700 mb-3">
          Over the years, we have successfully delivered cutting-edge solutions
          in web development, digital marketing, SEO, and graphic design. Our
          portfolio showcases a diverse range of projects, each demonstrating
          our commitment to innovation and client success.
        </p>
      </div>

      <div className="flex flex-col bg-blue-200 mt-4 sm:mt-0 px-6 sm:px-32 sm:py-12 text-center">
        <h2 className="text-red-700 text-3xl mb-4 font-semibold">
          Our Mission
        </h2>
        <p className="mt-8 text-gray-700 pb-10">
          At TechMintLab, our mission is to drive digital transformation by
          delivering innovative solutions that exceed expectations. We are
          dedicated to creating value for our clients and contributing to their
          long-term success.
        </p>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6"
          data-aos="fade-up"
        >
          <div
            className="bg-white p-6 rounded-full shadow-md border-b-2 border-green-900 bounce-item1 text-center flex flex-col items-center"
            data-aos="flip-up"
            data-aos-delay="0"
          >
            <div className="flex items-center justify-center">
              <FaTrophy className="text-3xl mb-2 text-orange-500" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Excellence</h3>
            <p className="text-gray-700">
              We strive for excellence in every project, delivering solutions
              that surpass expectations.
            </p>
          </div>

          <div
            className="bg-white p-6 rounded-full shadow-md border-t-2 border-amber-600 text-center bounce-item1"
            data-aos="flip-up"
            data-aos-delay="200"
          >
            <div className="flex items-center justify-center">
              <FaLightbulb className="text-3xl mb-2 text-yellow-500 items-center text-center" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Innovation</h3>
            <p className="text-gray-700">
              Embracing the latest technologies and methodologies, we bring
              innovative solutions to the table.
            </p>
          </div>

          <div
            className="bg-white p-6 rounded-full shadow-md border-b-2 border-amber-600 text-center bounce-item1"
            data-aos="flip-up"
            data-aos-delay="400"
          >
            <div className="flex items-center justify-center">
              <FaUserTie className="text-3xl mb-2 text-blue-900" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Client-Centric</h3>
            <p className="text-gray-700">
              Our clients are at the heart of what we do. We prioritize their
              needs and ensure their success.
            </p>
          </div>

          <div
            className="bg-white p-6 rounded-full shadow-md border-t-2 border-green-900 text-center bounce-item1"
            data-aos="flip-up"
            data-aos-delay="600"
          >
            <div className="flex items-center justify-center">
              <FaBook className="text-3xl mb-2 text-green-900" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Continuous Learning</h3>
            <p className="text-gray-700">
              In the fast-paced tech world, we believe in continuous learning
              and adaptation to stay ahead.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-8 sm:mt-0 px-6 sm:px-32 py-12">
        <h2 className="text-red-900 text-3xl mb-4 font-semibold">
          Achievements
        </h2>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          data-aos="fade-up"
        >
          <div className="bg-white p-6 rounded-md shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">
              Project Milestones
            </h3>
            <p className="text-gray-700">
              We've successfully completed over 67 projects, ranging from
              small-scale websites to complex enterprise solutions.
            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">
              Client Satisfaction
            </h3>
            <p className="text-gray-700">
              Our commitment to client satisfaction is reflected in our 99%
              client retention rate and positive feedback.
            </p>
          </div>
          <div className="bg-white p-6 rounded-md shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">
              Innovative Solutions
            </h3>
            <p className="text-gray-700">
              Our team consistently delivers innovative solutions that address
              the unique challenges of our clients.
            </p>
          </div>
        </div>
      </div>

      <section className="flex flex-col justify-center px-6 sm:px-32 py-12 bg-white">
        <div className="rounded-xl justify-start items-center inline-flex flex-col text-center">
          <div className="flex-col justify-start items-start inline-flex">
            <div className="mb-4">
              <span className="text-amber-500 text-2xl sm:text-4xl font-semibold font-be-vietnam-pro leading-10">
                Together
              </span>
              <span className="text-neutral-800 text-2xl sm:text-4xl font-semibold font-be-vietnam-pro leading-10">
                , let's shape the future of digital innovation
              </span>
            </div>
            <div className="text-neutral-600 text-base sm:text-lg font-normal font-be-vietnam-pro leading-relaxed">
              Join us on this exciting learning journey and unlock your
              potential in design and development.
            </div>
          </div>
          <button className="px-8 py-2 mb-14 text-white bg-amber-500 rounded-lg justify-center items-center gap-2 mt-5 sm:mt-5">
            <a href="/contact">Book Now</a>
          </button>
        </div>
      </section>
    </>
  );
}

export default About;
