import React from "react";
import founder from "../../assets/cofounderImage.jpg";
function FounderDetails() {
  return (
    <div className="flex justify-center  sm:flex-row flex-col items-center my-[100px]">
      <div>
        {" "}
        <img
          src={founder}
          alt="techmintlab-Founder Satyam Kumar"
          className=" w-[250px] h-[250px] object-contain  "
        />
      </div>
      <div>
        <p className="text-2xl text-center font-bold font-serif">
          We would love to work with you
        </p>
        <p className="text-sm text-center font-extralight font-serif">
          If you are looking for a technology partner, rather than just another
          outsourcing agency, have a chat with our{" "}
          <b className="font-bold"> Agency.</b> Let's talk about how
          we can support your business' software requirements.
        </p>
        <p className="text-center mt-5">
          <a
            className="border hover:bg-red-500 transition  p-2 rounded-md text-white bg-red-600"
            href="tel:+918059424475"
          >
            Call Now
          </a>
        </p>
      </div>
    </div>
  );
}

export default FounderDetails;
