import React, { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

function FAQ() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleIconClick = (identifier) => {
    setActiveAccordion(activeAccordion === identifier ? null : identifier);
  };

  return (
    <section className="sm:px-20 px-10">
      <div
        className="p-4 bg-white rounded-lg dark:bg-gray-800"
        id="faq"
        role="tabpanel"
        aria-labelledby="faq-tab"
      >
        <div
          id="accordion-flush"
          data-accordion="collapse"
          data-active-classes="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
          data-inactive-classes="text-gray-500 dark:text-gray-400"
        >
          <div>
            {/* Question 1 */}
            <h2 id="accordion-flush-heading-1">
              <button
                type="button"
                className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                data-accordion-target="#accordion-flush-body-1"
                aria-expanded={activeAccordion === "1"}
                aria-controls="accordion-flush-body-1"
                onClick={() => handleIconClick("1")}
              >
                <span>
                  What is the minimum project budget your team accepts?
                </span>
                <MdArrowDropDown />
              </button>
            </h2>
            <div
              id="accordion-flush-body-1"
              className={activeAccordion === "1" ? "" : "hidden"}
              aria-labelledby="accordion-flush-heading-1"
            >
              <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Answer: Our pricing is tailored to the unique requirements of
                  each project. While we prioritize delivering value within
                  budget
                </p>
                <p className="text-gray-500 dark:text-gray-400">
                  constraints, the minimum project budget varies based on
                  complexity and scope. Contact us for a personalized quote.{" "}
                  <a
                    href="/services"
                    className="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    get started
                  </a>{" "}
                  Quality is our top priority. We follow industry best
                  practices,
                </p>
              </div>
            </div>
          </div>

          <div>
            {/* Question 2 */}
            <h2 id="accordion-flush-heading-2">
              <button
                type="button"
                className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                data-accordion-target="#accordion-flush-body-2"
                aria-expanded={activeAccordion === "2"}
                aria-controls="accordion-flush-body-2"
                onClick={() => handleIconClick("2")}
              >
                <span>How do you handle project timelines and deadlines?</span>
                <MdArrowDropDown />
              </button>
            </h2>
            <div
              id="accordion-flush-body-2"
              className={activeAccordion === "2" ? "" : "hidden"}
              aria-labelledby="accordion-flush-heading-2"
            >
              <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Answer: We follow an agile development methodology, breaking
                  projects into sprints and delivering incremental results.
                  Timelines are established collaboratively, and our team is
                  committed to meeting deadlines. We maintain transparent
                  communication throughout the development process.
                </p>
              </div>
            </div>
          </div>

          <div>
            {/* Question 3 */}
            <h2 id="accordion-flush-heading-3">
              <button
                type="button"
                className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                data-accordion-target="#accordion-flush-body-3"
                aria-expanded={activeAccordion === "3"}
                aria-controls="accordion-flush-body-3"
                onClick={() => handleIconClick("3")}
              >
                <span>
                  Can I request changes to the project after development has
                  started?
                </span>
                <MdArrowDropDown />
              </button>
            </h2>
            <div
              id="accordion-flush-body-3"
              className={activeAccordion === "3" ? "" : "hidden"}
              aria-labelledby="accordion-flush-heading-3"
            >
              <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Answer: We understand that project requirements may evolve. We
                  accommodate reasonable change requests and offer flexibility
                  during the development process. Any changes in scope or
                  requirements are discussed, and their impact on timeline and
                  budget is communicated transparently.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Security is integral to our development process. We implement
                  industry-standard security measures, conduct regular security
                  audits, and follow best practices to safeguard your software
                  against potential vulnerabilities.
                </p>
              </div>
            </div>
          </div>

          {/* Add more questions following the same pattern */}
          <div>
            {/* Question 3 */}
            <h2 id="accordion-flush-heading-3">
              <button
                type="button"
                className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                data-accordion-target="#accordion-flush-body-3"
                aria-expanded={activeAccordion === "3"}
                aria-controls="accordion-flush-body-3"
                onClick={() => handleIconClick("3")}
              >
                <span>
                  Do you provide documentation for the software developed?
                </span>
                <MdArrowDropDown />
              </button>
            </h2>
            <div
              id="accordion-flush-body-3"
              className={activeAccordion === "3" ? "" : "hidden"}
              aria-labelledby="accordion-flush-heading-3"
            >
              <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  Answer: Yes, we provide comprehensive documentation for all
                  our software projects. This includes user manuals, technical
                  documentation, and any necessary guides to ensure seamless
                  operation and future enhancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
