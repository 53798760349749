import { Link } from "react-router-dom";

export const Card = ({ className, cardHeader, text }) => {
  return (
    <div
      className={`transition-transform duration-300 transform hover:scale-105 ${className}`}
    >
      <div className="bg-white p-6 rounded-md shadow-md">
        <h3 className="sm:text-xl uppercase text-lg font-semibold mb-4">
          {cardHeader}
        </h3>
        <p className="text-gray-600 sm:text-lg text-sm ">{text}</p>
      </div>
    </div>
  );
};

export const ServicesCard = ({
  className,
  cardHeader,
  text,
  imageSrc,
  buttonText,
}) => {
  return (
    <div
      className={`transition-transform duration-300 transform hover:scale-105 ${className}`}
    >
      <div className=" text-white border border-y-green-600 border-x-gray-400 bg-black p-6 rounded-md shadow-md">
        <img src={imageSrc} alt="Description of the image" />
        <h3 className="text-lg font-semibold my-2">{cardHeader}</h3>
        <p className="text-gray-200 sm:text-xl text-sm">{text}</p>
        <div className="flex justify-center">
          <Link
            to="contact"
            class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mt-2 min-w-full"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};
