import React from "react";

function CodingTechnology() {
  return (
    <div className="my-2">
      <p className="text-center font-extrabold text-xl md:text-3xl font-serif">
        Checkout some of the latest
      </p>
      <p className="text-center font-extrabold text-xl md:text-3xl font-serif">
        technologies we work on
      </p>
      <div className="flex gap-x-3 my-4 gap-y-5 justify-center items-center flex-wrap">
        <img
          className="w-[50px]  h-[50px] "
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcR5U16C8yXgBpl7-Bc7Itjx3_LRl425zINA&s"
          alt="Techmintlab-software development In react js Compony In Noida"
        />
        <img
          className="w-[50px]  h-[50px] "
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ18Gt9viR2yfaYTVjyutOeMAGk6sFTje4g2A&s"
          alt="Techmintlab-software development In react Native Compony In Noida"
        />
        <img
          className="w-[50px]  h-[50px] "
          src="https://cdn-images-1.medium.com/max/1200/1*5-aoK8IBmXve5whBQM90GA.png"
          alt="Techmintlab-software development In Flutter Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1200px-Vue.js_Logo_2.svg.png"
          alt="Techmintlab-software development In View js Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://miro.medium.com/v2/resize:fit:588/1*15CYVZdpsxir8KLdxEZytg.png"
          alt="Techmintlab-software development In Angular js Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ494s97h7aVykAjvgNeKOGnjjgUPqCAP_BA&s"
          alt="Techmintlab-software development In Nuxt js Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://static-00.iconduck.com/assets.00/nextjs-icon-512x512-y563b8iq.png"
          alt="Techmintlab-software development In Next js Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://w7.pngwing.com/pngs/399/620/png-transparent-laravel-hd-logo.png"
          alt="Techmintlab-software development In Laravel Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png"
          alt="Techmintlab-software development In javascript Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzsM6zsSVj3LttmwxNWDO-TC7EpxXKvABpog&s"
          alt="Techmintlab-software development In Node js Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/1200px-PHP-logo.svg.png"
          alt="Techmintlab-software development In Php Compony In Noida"
        />
        <img
          className="w-[70px] h-[60px] "
          src="https://logos-world.net/wp-content/uploads/2022/07/Java-Logo.png"
          alt="Techmintlab-software development In JAVA compony In Noida"
        />
      </div>
      <div className="flex gap-x-12 my-8 gap-y-5 justify-center items-center flex-wrap">
        {numberComp.map((item, index) => {
          return (
            <div
              className="flex  bg-slate-700 p-3  rounded-xl flex-col items-center justify-center gap-0"
              key={index}
            >
              <h1 className="text-white text-[20px] font-extralight">
                {item.number}+
              </h1>
              <p className="text-white text-[18px] font-semibold font-sans tracking-wider">
                {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CodingTechnology;

const numberComp = [
  {
    number: 17,
    text: "team members",
  },
  {
    number: 45,
    text: "projects completed",
  },
  {
    number: 4,
    text: "years of experience",
  },
  {
    number: 72,
    text: "happy clients",
  },
  {
    number: 4,
    text: "awards won",
  },
];
