import React, { useState, useEffect } from "react";
import hero_image from "../../assets/growyourbusiness2.png";
import service_card2 from "../../assets/mainbanner.png";
import service_card3 from "../../assets/sales Presentation1.png"
import service_card4 from "../../assets/graphicdesigningmain.png"

function Corousel() {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [hero_image, service_card2, service_card4, service_card3];

  const handlePrev = () => {
    setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    setActiveSlide((prev) => (prev + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [activeSlide]);

  return (
    <div
      className="relative text-center sm:px-44 px-8 py-12 bg-green-600 w-full"
      data-carousel="slide"
    >
      {/* Carousel wrapper */}
      <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${
              index === activeSlide ? "opacity-100" : "opacity-0"
            } transition-opacity duration-700 ease-in-out absolute inset-0`}
            data-carousel-item
          >
            <img
              src={slide}
              className="absolute block w-full h-[120%]  "
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>
      {/* Indicators */}
      <div className="absolute z-30 bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 rtl:space-x-reverse">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full transition duration-300 ease-in-out ${
              index === activeSlide
                ? "bg-gray-800"
                : `bg-gray-300 hover:bg-gray-${Math.min(800 - 50 * index, 600)}`
            }`}
            onClick={() => setActiveSlide(index)}
            aria-current={index === activeSlide ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
            data-carousel-slide-to={index}
          ></button>
        ))}
      </div>
      {/* Slider controls */}
      <button
        type="button"
        className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handlePrev}
      >
        {/* ... (unchanged) ... */}
      </button>
      <button
        type="button"
        className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handleNext}
      >
        {/* ... (unchanged) ... */}
      </button>
    </div>
  );
}

export default Corousel;
