import React from "react";
import hero_image from "../../assets/logo.png";
import { HiOutlineLightBulb } from "react-icons/hi";
import { Link } from "react-router-dom";

const ServiceCard = ({ title, description, buttonText }) => {
  return (
    <div className="bg-gray-900 mb-8 sm:mb-10 p-4 sm:p-6 md:p-8 lg:p-10 flex flex-col rounded-lg hover:border-green-600 border border-purple-700 space-y-3 gap-4 shadow-md mx-4 transform transition-transform duration-300 hover:scale-105">
      <div className="flex flex-col md:flex-row">
        <img
          src={hero_image}
          alt=""
          className="w-full sm:w-40 md:w-48 lg:w-56 mr-4 mb-4 md:mb-0"
        />

        <div className="flex flex-col">
          <h2 className="text-xl sm:text-2xl text-orange-600 md:text-3xl lg:text-4xl font-bold mb-2">
            {title}
          </h2>
          <p className="text-gray-300 mb-4">{description}</p>
    
        </div>
      </div>      <div className="flex bg-slate-400 justify-end">
            <button className="bg-orange-500  sm:w-auto text-white px-4 py-2 rounded-md">
              <Link to="/contact">{buttonText}</Link>
            </button>
          </div>
    </div>
  );
};

function Services() {
  return (
    <div className="bg-gray-800 text-white">
      <section className="sm:px-20 px-10 py-10">
        <div className="flex justify-center">
          <h6 className="text-green-300 sm:text-2xl text-xl font-semibold font-['Be Vietnam Pro'] leading-10">
            Discover which service fits for you.
          </h6>
        </div>
        <div className="flex justify-start">
          <p className="text-gray-200 sm:text-xl text-md font-normal font-['Be Vietnam Pro'] leading-normal">
            Discover premier solutions that are tailored to your business. Let
            our team help you find the best digital marketing services to fit
            your unique needs and budget.
          </p>
        </div>
      </section>
      <div className="flex flex-col justify-center px-1 sm:px-20 space-y-8">
        <ServiceCard
          title="Digital Marketing Excellence"
          description="Drive brand awareness, engagement, and conversions with our strategic digital marketing solutions. From social media management to targeted campaigns, we amplify your brand's online presence."
          buttonText="Free Demo"
        />
        <ServiceCard
          title=" Graphic Design with Impact"
          description="Make a lasting impression with visually striking designs. Our creative team transforms ideas into captivating visuals, whether it's for social media graphics, websites, or comprehensive branding materials."
          buttonText="Free Demo"
        />
        <ServiceCard
          title="Social Media Marketing"
          description="Social media is a powerful tool these days for all kinds of businesses, and we are here to help you make the most of it. Explore now and discover the full spectrum of social media marketing services that our team of social media experts can offer you."
          buttonText="Free Demo"
        />{" "}
        <ServiceCard
          title=" Software Development Services"
          description="Unlock the potential of bespoke software solutions. Our development services cater to a variety of needs, providing tailor-made software that aligns with your business goals and streamlines operations."
          buttonText="Free Demo"
        />
        <ServiceCard
          title=" App and Web Design Excellence"
          description="Make a lasting impression with our design excellence. Whether it's crafting intuitive app interfaces or designing visually appealing websites, we blend creativity with functionality to deliver an exceptional user experience."
          buttonText="Free Demo"
        />
        <ServiceCard
          title="  E-commerce Solutions"
          description="Optimize your online store with our e-commerce solutions. From payment gateway integration to user-centric design, we create seamless and secure shopping experiences that boost conversions."
          buttonText="Free Demo"
        />
        <div className="flex justify-center items-center py-10">
          <HiOutlineLightBulb className="w-8 h-8 mr-2" />
          <p className="text-lg">
            Unlock the potential of your business with our premium services.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
